





































































































































import Vue from "vue";
import { HelpingMessage } from "@/components/atom/helping";
import PageHeader from "@/components/atom/PageHeader.vue";
import AccountingCarryForwardAddModal from "@/components/core/accounting/AccountingCarryForwardAddModal.vue";
import DialogSubscribe from "../DialogSubscribe.vue";
import { ArticleEnum } from "@/composables/crisp.usable";
import {
  TaxDeclaration2033Compute,
  TaxDeclaration2033LineCompute,
} from "@/models";
import {
  accountingPeriodsStore,
  documentsStore,
  subscriptionsStore,
  tasksStore,
} from "@/store";
import {
  Direction,
  getReferredIdByTypeWithReferences,
  isTaxDeclaration2033LineNumberCompositionOfShareCapital,
  isTaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses,
  isTaxDeclaration2033LineNumberSimplifiedBalanceSheet,
  isTaxDeclaration2033LineNumberSimplifiedIncomeStatement,
  isTaxDeclaration2033LineNumberStatementOfProvisionsExceptionalDepreciationDeficit,
  SubscriptionsModel,
  TaskTypeReference,
  TaskCode,
  TaxDeclaration2033,
  TaxDeclaration2033LineNumber,
  TaxDeclaration2033LineNumberCompositionOfShareCapital,
  TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses,
  TaxDeclaration2033LineNumberSimplifiedBalanceSheet,
  TaxDeclaration2033LineNumberSimplifiedIncomeStatement,
  TaxDeclaration2033LineNumberStatementOfProvisionsExceptionalDepreciationDeficit,
  taxDeclaration2033LinesMetadata,
  TaxRegime,
} from "@edmp/api";
import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  PropType,
  Ref,
  ref,
} from "@vue/composition-api";
import { isArray } from "lodash";
import TaxDeclaration2033Lines from "./TaxDeclaration2033Lines.vue";
import { taxDeclarationsService } from "@/services/TaxDeclarations.service";

export default defineComponent({
  name: "TaxDeclaration2033",
  components: {
    PageHeader,
    HelpingMessage,
    TaxDeclaration2033Lines,
    AccountingCarryForwardAddModal,
    DialogSubscribe,
  },
  props: {
    openAddDeferrableDeficit: { type: String as PropType<"true"> },
    lineNumberToScroll: {
      type: String as PropType<TaxDeclaration2033LineNumber>,
    },
  },
  setup(props, context) {
    const isOpenAddDeferrableDeficit = ref(
      Boolean(props.openAddDeferrableDeficit)
    );
    const isOpenDialogSubscribe = ref(false);

    const scrollToLine = (lineNumber) => {
      Vue.nextTick(() => {
        const refKey = `L${lineNumber}`;
        const element = document.getElementById(refKey);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      });
    };

    const onDeferrableDeficitModalFinish = async () => {
      isOpenAddDeferrableDeficit.value = false;
      const fillCerfa2033Task = tasksStore.notCompletedTasks.find(
        (task) =>
          getReferredIdByTypeWithReferences(
            task.references,
            TaskTypeReference.accountingPeriod
          ) === accountingPeriodsStore.currentId &&
          task.code === TaskCode.FillCerfa2033
      );
      if (fillCerfa2033Task) {
        await tasksStore.validateTask({
          taskLocal: fillCerfa2033Task,
        });
      }
      init();

      Vue.nextTick(() => {
        if (context.refs.deficitSection) {
          context.refs.deficitSection[0].scrollIntoView({ behavior: "smooth" });
        }
      });
    };
    const deficitSectionTitle =
      "Relevé des provisions - Amortissements dérogatoires - Déficit";

    const taxDeclaration2033: Ref<TaxDeclaration2033 | undefined> = ref();
    const subscription: Ref<SubscriptionsModel.Subscription | undefined> =
      ref();

    const taxDeclaration2033LinesMetadataByProduct = computed(() =>
      accountingPeriodsStore.isIS
        ? taxDeclaration2033LinesMetadata[TaxRegime.IS_2065]
        : accountingPeriodsStore.isLMNP
        ? taxDeclaration2033LinesMetadata[TaxRegime.LMNP_2031]
        : undefined
    );
    const taxDeclaration2033Compute = computed(() => {
      const sectionTitles = {
        "Bilan simplifié": {
          Actif: [
            TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L014,
            TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L016,
            TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L014_016_NET,
            TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L028,
            TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L030,
            TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L028_030_NET,
            TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L040,
            TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L044,
            TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L048,
            TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L044_048_NET,
            TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L068,
            TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L072,
            TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L080,
            TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L084,
            TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L096,
            TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L098,
            TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L096_098_NET,
            TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L110,
            TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L112,
            TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L110_112_NET,
          ],
          Passif: [
            TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L120,
            TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L126,
            TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L134,
            TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L136,
            TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L142,
            TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L154,
            TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L156,
            TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L166,
            TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L169,
            TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L172,
            ...(accountingPeriodsStore.isIS
              ? [TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L173]
              : []),
            TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L176,
            TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L180,
          ],
        },
        "Compte de résultat simplifié de l'exercice": {
          "Résultat comptable": [
            TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L218,
            TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L226,
            TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L230,
            TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L232,
            TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L242,
            ...(accountingPeriodsStore.isLMNP
              ? [TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L243]
              : []),
            TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L244,
            TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L254,
            TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L264,
            TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L270,
            TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L280,
            TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L290,
            TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L294,
            TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L300,
            TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L306,
            TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L310,
          ],
          "Résultat fiscal": [
            TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L312,
            TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L314,
            TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L318,
            TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L324,
            TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L352,
            TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L354,
            TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L356,
            TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L360_TOTAL,
            TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L360,
            TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L370,
            TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L372,
          ],
        },
        "Immobilisations - Amortissements - Plus values - Moins values": {
          Immobilisations: [
            TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L410,
            TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L412,
            TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L414,
            TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L416,
            TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L420,
            TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L422,
            TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L424,
            TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L426,
            TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L430,
            TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L432,
            TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L434,
            TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L436,
            TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L440,
            TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L442,
            TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L444,
            TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L446,
            TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L450,
            TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L452,
            TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L454,
            TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L456,
            TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L490,
            TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L492,
            TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L494,
            TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L496,
          ],
          Amortissements: [
            TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L500,
            TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L502,
            TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L504,
            TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L506,
            TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L510,
            TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L512,
            TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L514,
            TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L516,
            TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L520,
            TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L522,
            TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L524,
            TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L526,
            TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L530,
            TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L532,
            TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L534,
            TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L536,
            TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L540,
            TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L542,
            TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L544,
            TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L546,
            TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L570,
            TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L572,
            TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L574,
            TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L576,
          ],
        },
        [deficitSectionTitle]: {
          "Déficits reportables": [
            TaxDeclaration2033LineNumberStatementOfProvisionsExceptionalDepreciationDeficit.L982,
            TaxDeclaration2033LineNumberStatementOfProvisionsExceptionalDepreciationDeficit.L983,
            TaxDeclaration2033LineNumberStatementOfProvisionsExceptionalDepreciationDeficit.L984,
            TaxDeclaration2033LineNumberStatementOfProvisionsExceptionalDepreciationDeficit.L860,
            TaxDeclaration2033LineNumberStatementOfProvisionsExceptionalDepreciationDeficit.L870,
          ],
        },
        ...(accountingPeriodsStore.isIS
          ? {
              "Composition du capital social": Object.values(
                TaxDeclaration2033LineNumberCompositionOfShareCapital
              ),
            }
          : {}),
      };

      const formatLines = (linesNumber: TaxDeclaration2033LineNumber[]) =>
        linesNumber
          .map((lineNumber) => {
            if (
              isTaxDeclaration2033LineNumberSimplifiedBalanceSheet(
                lineNumber
              ) ||
              isTaxDeclaration2033LineNumberSimplifiedIncomeStatement(
                lineNumber
              ) ||
              isTaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses(
                lineNumber
              ) ||
              isTaxDeclaration2033LineNumberStatementOfProvisionsExceptionalDepreciationDeficit(
                lineNumber
              )
            ) {
              return {
                lineNumber,
                title: `Ligne ${lineNumber}`,
                ...taxDeclaration2033LinesMetadataByProduct.value?.[lineNumber],
                ...taxDeclaration2033.value?.lines[lineNumber],
                ...(subscription.value
                  ? { subscriptionPlanType: subscription.value.plan.type }
                  : {}),
                value: taxDeclaration2033.value?.lines[lineNumber]
                  ? subscription.value
                    ? subscription.value.plan.type ===
                      SubscriptionsModel.PlanType.Free
                      ? "€"
                      : `${
                          taxDeclaration2033.value?.lines[
                            lineNumber as
                              | TaxDeclaration2033LineNumberSimplifiedIncomeStatement
                              | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses
                          ].amountDirection === Direction.debit
                            ? "-"
                            : ""
                        }${taxDeclaration2033.value?.lines[
                          lineNumber as
                            | TaxDeclaration2033LineNumberSimplifiedIncomeStatement
                            | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses
                        ].amount.toLocaleString()}€`
                    : "0 €"
                  : "0 €",
              };
            }
            if (
              isTaxDeclaration2033LineNumberCompositionOfShareCapital(
                lineNumber
              )
            ) {
              return {
                lineNumber,
                title: `Ligne ${lineNumber}`,
                ...taxDeclaration2033LinesMetadataByProduct.value?.[lineNumber],
                ...taxDeclaration2033.value?.lines[
                  lineNumber as TaxDeclaration2033LineNumberCompositionOfShareCapital
                ],
                value: taxDeclaration2033.value?.lines[lineNumber]
                  ? `${
                      taxDeclaration2033.value?.lines[
                        lineNumber as TaxDeclaration2033LineNumberCompositionOfShareCapital
                      ].text
                    }`
                  : "0",
              };
            }
          })
          .filter(
            (taxDeclaration2033Compute) =>
              taxDeclaration2033Compute !== undefined
          ) as TaxDeclaration2033LineCompute;

      const taxDeclaration2033Compute = Object.entries(sectionTitles).reduce(
        (taxDeclaration2033Compute, [title, subTitleOrLinesNumber]) => {
          if (isArray(subTitleOrLinesNumber)) {
            const linesNumber: TaxDeclaration2033LineNumber[] =
              subTitleOrLinesNumber;
            taxDeclaration2033Compute[title] = formatLines(linesNumber);
          } else {
            const taxDeclaration2033SubCompute = Object.entries(
              subTitleOrLinesNumber
            ).reduce(
              (taxDeclaration2033SubCompute, [subTitle, linesNumber]) => {
                taxDeclaration2033SubCompute[subTitle] =
                  formatLines(linesNumber);
                return taxDeclaration2033SubCompute;
              },
              {} as { [sectionSubTitle: string]: TaxDeclaration2033LineCompute }
            );

            taxDeclaration2033Compute[title] = taxDeclaration2033SubCompute;
          }
          return taxDeclaration2033Compute;
        },
        {} as TaxDeclaration2033Compute
      );

      return taxDeclaration2033Compute;
    });

    const documents = computed(() =>
      documentsStore.documents.filter(
        (document) =>
          document.tags?.includes("tax") &&
          document.createdBy === "ownily" &&
          document.product?.accountingPeriod?.id ===
            accountingPeriodsStore.currentId
      )
    );

    const rules = computed(() => ({
      isEqualTotalActifTotalPasif:
        (
          taxDeclaration2033Compute.value["Bilan simplifié"][
            "Actif"
          ] as TaxDeclaration2033LineCompute
        ).find(
          (line) =>
            line.lineNumber ===
            TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L110_112_NET
        )?.value ===
        (
          taxDeclaration2033Compute.value["Bilan simplifié"][
            "Passif"
          ] as TaxDeclaration2033LineCompute
        ).find(
          (line) =>
            line.lineNumber ===
            TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L180
        )?.value,
    }));

    // Init
    const init = async () => {
      taxDeclaration2033.value = await taxDeclarationsService.get2033({
        accountingPeriodId: accountingPeriodsStore.currentId,
      });
      documentsStore.fetchDocuments();
      subscription.value = subscriptionsStore.getSubscriptionByProduct(
        taxDeclaration2033.value.productId
      );
      if (subscription.value?.plan.type === SubscriptionsModel.PlanType.Free) {
        isOpenDialogSubscribe.value = true;
      }
    };

    onBeforeMount(async () => {
      await init();
    });
    onMounted(async () => {
      if (props.lineNumberToScroll) {
        scrollToLine(props.lineNumberToScroll);
      }
    });
    return {
      isOpenDialogSubscribe,
      init,
      accountingPeriodsStore,
      rules,
      documents,
      taxDeclaration2033Compute,
      isArray,
      ArticleEnum,
      isOpenAddDeferrableDeficit,
      onDeferrableDeficitModalFinish,
      deficitSectionTitle,
    };
  },
});
