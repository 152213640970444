
























































































































import Notifications from "@/components/core/notifs/Notifications.vue";
import { useCrisp } from "@/composables/crisp.usable";
import { dispatchHelperCenterEvent, HelpCenterEventCode } from "@/events";
import { ROUTE_NAMES } from "@/router/routes";
import {
  accountingPeriodsStore,
  coreStore,
  productsStore,
  tasksStore,
  usersStore,
} from "@/store";
import { getMoment, TaxRegime } from "@edmp/api";
import { computed, defineComponent } from "@vue/composition-api";
import ItemsNavigation from "../navigation/ItemsNavigation.vue";
import Profile from "./Profile.vue";

export default defineComponent({
  name: "Header",
  components: {
    Notifications,
    Profile,
    ItemsNavigation,
  },
  setup(props, context) {
    function openMenuMobile() {
      coreStore.openMobileMenu();
    }

    function closeMenuMobile() {
      coreStore.closeMobileMenu();
    }

    function goProductTour() {
      context.root.$router.push({ name: ROUTE_NAMES.Dashboard });
      context.root.$tours["ownily-tour"].start();
    }

    function goSubscriptions() {
      context.root.$router.push({ name: ROUTE_NAMES.Subscriptions });
    }

    const currentAccountingPeriodIsIR =
      accountingPeriodsStore.currentAccountingPeriod?.taxRegime ===
      TaxRegime.IR_2072;

    const { displayHelpSearch } = useCrisp();

    return {
      openMenuMobile,
      closeMenuMobile,
      goProductTour,
      goSubscriptions,
      isMenuMobileOpened: computed(() => coreStore.isOpenMobileMenu),
      nbNotifications: computed(() => tasksStore.userNotificationsTasks.length),
      displayHelpSearch: () => {
        dispatchHelperCenterEvent({
          userId: usersStore.loggedInUser.id,
          productId: productsStore.currentId,
          date: getMoment().toISOString(),
          code: HelpCenterEventCode.CLICK_FROM_HEADER,
        });
        displayHelpSearch();
      },
      currentAccountingPeriodIsIR,
    };
  },
});
