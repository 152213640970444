var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.transactionState.selectedCategory)?_c('div',{staticClass:"categorizationStep1"},[_c('div',{staticClass:"selectedCategory mb-6"},[_c('span',{staticClass:"mr-2"},[_vm._v("Catégorie sélectionnée :")]),_c('v-btn',{attrs:{"color":"success","depressed":""},on:{"click":function($event){return _vm.changeCategory()}}},[_vm._v(" "+_vm._s(_vm.transactionState.selectedCategory.name)+" "),_c('v-icon',{staticClass:"ml-1"},[_vm._v(" mdi-pencil-outline")])],1),(_vm.fixedAsset.show)?_c('v-btn',{staticClass:"ml-1",attrs:{"color":"#000","icon":""},on:{"click":_vm.openArticleFixedAsset}},[_c('v-icon',[_vm._v("mdi-help-circle-outline")])],1):_vm._e()],1),(_vm.fixedAsset.show)?_c('v-alert',{staticClass:"pl-2 pr-2 pt-2 pb-2",attrs:{"outlined":"","text":"","type":"info"}},[_vm._v(" Une charge de travaux ou l'achat de meubles peuvent être immobilisés uniquement si sa valeur est supérieure ou égale à 500€HT unitaire. "),_c('br'),_vm._v("Si votre charges fait plus de 500€ au total mais payée en plusieurs fois (400€ équipements puis 200€ facture 1 puis 300€ facture 2) alors vous avez le droit d'amortir en liant toutes les transactions à la même immobilisation dans Ownily. "),_c('a',{attrs:{"href":"https://ownily.crisp.help/fr/article/pourquoi-et-comment-immobiliser-les-travaux-ou-achat-de-meubles-kzkjkw/","target":"_blank"}},[_vm._v("Voir l'article ici")]),_c('br'),_vm._v("Si la valeur est inférieur à 500€HT unitaire au total, alors vous devez choisir : "),_c('ul',[_c('li',[_vm._v("dans TRAVAUX, Entretien et réparation")]),_c('li',[_vm._v(" dans FRAIS DE FONCTIONNEMENT, Petits équipements (meubles & cuisine électroménager) ")])])]):_vm._e(),(
      _vm.transactionState.selectedCategory.number === _vm.LedgerAccountEnum.N625100
    )?_c('v-alert',{staticClass:"pl-2 pr-2 pt-2 pb-2",attrs:{"outlined":"","text":"","type":"info"}},[_c('a',{attrs:{"href":"https://bpifrance-creation.fr/boiteaoutils/bareme-kilometrique-2023","target":"_blank"}},[_vm._v("Voici comment calculer les frais kilométriques")])]):_vm._e(),_c('v-form',{ref:"form"},[_c('div',[(_vm.realEstateAsset.show)?_c('v-row',{attrs:{"id":"test_select_realEstateAsset"}},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-select',{attrs:{"items":_vm.realEstateAsset.list,"rules":[
              function () { return !!_vm.realEstateAsset.selected || 'Sélectionnez un bien'; } ],"item-text":"name","item-value":"id","label":"Bien","hint":_vm.categorizationUse.isOptional(_vm.TypeReference.realEstateAsset)
                ? 'Facultatif'
                : '',"persistent-hint":"","outlined":"","required":"","return-object":""},scopedSlots:_vm._u([(
                _vm.transactionState.selectedCategory &&
                _vm.transactionState.selectedCategory.number === '706000'
              )?{key:"selection",fn:function(props){return [_c('div',{staticClass:"select-badge"},[_vm._v(" "+_vm._s(props.item.name)+" "),(
                    _vm.realEstateAssetsStore.getRentalUnitByRealEstateAssetId(
                      props.item.id
                    ).taxTvaEnable
                  )?_c('div',{staticClass:"badge-tax"},[_vm._v(" TVA "+_vm._s(_vm.realEstateAssetsStore.getRentalUnitByRealEstateAssetId( props.item.id ).taxRateTVA)+"% ")]):_vm._e()])]}}:null,(
                _vm.transactionState.selectedCategory &&
                _vm.transactionState.selectedCategory.number === '706000'
              )?{key:"item",fn:function(props){return [_c('div',{staticClass:"select-badge"},[_vm._v(" "+_vm._s(props.item.name)+" "),(
                    _vm.realEstateAssetsStore.getRentalUnitByRealEstateAssetId(
                      props.item.id
                    ).taxTvaEnable
                  )?_c('div',{staticClass:"badge-tax"},[_vm._v(" TVA "+_vm._s(_vm.realEstateAssetsStore.getRentalUnitByRealEstateAssetId( props.item.id ).taxRateTVA)+"% ")]):_vm._e()])]}}:null,{key:"append-item",fn:function(){return [_c('v-divider',{staticClass:"mb-2"}),_c('v-btn',{attrs:{"id":"track-add_real_estates_second","text":"","width":"100%","color":"primary"},on:{"click":_vm.createRealEstateAsset}},[_c('v-icon',{attrs:{"dense":"","left":""}},[_vm._v("mdi-plus")]),_vm._v(" Ajouter un bien ")],1)]},proxy:true}],null,true),model:{value:(_vm.realEstateAsset.selected),callback:function ($$v) {_vm.$set(_vm.realEstateAsset, "selected", $$v)},expression:"realEstateAsset.selected"}})],1),_c('v-col',{attrs:{"cols":"4"}})],1):_vm._e(),_c('DialogRight',{attrs:{"dialogModel":_vm.realEstateAsset.create}},[(_vm.realEstateAsset.create)?_c('RealEstateAssetCreate',{attrs:{"productId":_vm.categorizationUse.product.value.id,"isEditable":true,"smallDisplay":true},on:{"close":function($event){_vm.realEstateAsset.create = false},"validate":function (realEstateAssetId) {
              _vm.realEstateAsset.selected = _vm.realEstateAsset.list.find(
                function (value) { return value.id === realEstateAssetId; }
              );
              _vm.realEstateAsset.create = false;
            }}}):_vm._e()],1),(_vm.fixedAsset.show)?_c('v-row',{attrs:{"id":"test_select_fixedAsset"}},[_c('v-col',{attrs:{"cols":"8"}},[(_vm.fixedAsset.show)?_c('v-select',{attrs:{"items":_vm.fixedAsset.list,"rules":[
              function () { return !!_vm.fixedAsset.selected || 'Sélectionnez une immobilisation'; } ],"item-text":"name","item-value":"id","label":"Immobilisation","hint":_vm.categorizationUse.isOptional(_vm.TypeReference.fixedAsset)
                ? 'Facultatif'
                : '',"persistent-hint":"","outlined":"","required":"","return-object":""},scopedSlots:_vm._u([{key:"append-item",fn:function(){return [_c('v-divider',{staticClass:"mb-2"}),_c('v-btn',{attrs:{"text":"","width":"100%","color":"primary"},on:{"click":function($event){_vm.fixedAsset.create = true}}},[_c('v-icon',{attrs:{"dense":"","left":""}},[_vm._v("mdi-plus")]),_vm._v(" Ajouter une immobilisation ")],1)]},proxy:true}],null,false,3610615300),model:{value:(_vm.fixedAsset.selected),callback:function ($$v) {_vm.$set(_vm.fixedAsset, "selected", $$v)},expression:"fixedAsset.selected"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"4"}})],1):_vm._e(),_c('DialogRight',{attrs:{"dialogModel":_vm.fixedAsset.create}},[(_vm.fixedAsset.create)?_c('FixedAssetCreate',{attrs:{"productId":_vm.categorizationUse.product.value.id,"isEditable":true,"smallDisplay":true},on:{"close":function($event){_vm.fixedAsset.create = false},"validate":function (fixedAssetId) {
              _vm.fixedAsset.selected = _vm.fixedAsset.list.find(
                function (value) { return value.id === fixedAssetId; }
              );
              _vm.fixedAsset.create = false;
            }}}):_vm._e()],1),(_vm.tenant.show)?_c('v-row',{attrs:{"id":"test_select_tenant"}},[_c('v-col',{attrs:{"cols":"8"}},[(_vm.tenant.show)?_c('v-select',{attrs:{"disabled":!_vm.rentalUnit ? true : false,"items":_vm.tenant.list,"rules":[function () { return !!_vm.tenant.selected || 'Sélectionnez un locataire'; }],"item-text":"name","item-value":"id","label":"Locataire","hint":_vm.categorizationUse.isOptional(_vm.TypeReference.tenant)
                ? 'Facultatif'
                : '',"persistent-hint":"","outlined":"","required":"","return-object":""},scopedSlots:_vm._u([{key:"append-item",fn:function(){return [_c('v-divider',{staticClass:"mb-2"}),_c('v-btn',{attrs:{"text":"","width":"100%","color":"primary"},on:{"click":function($event){_vm.tenant.create = true}}},[_c('v-icon',{attrs:{"dense":"","left":""}},[_vm._v("mdi-plus")]),_vm._v(" Ajouter un locataire ")],1)]},proxy:true}],null,false,424852207),model:{value:(_vm.tenant.selected),callback:function ($$v) {_vm.$set(_vm.tenant, "selected", $$v)},expression:"tenant.selected"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"4"}})],1):_vm._e(),_c('DialogRight',{attrs:{"dialogModel":_vm.tenant.create}},[(_vm.tenant.create && _vm.rentalUnit)?_c('TenantCreate',{attrs:{"productId":_vm.categorizationUse.product.value.id,"realEstateAsset":_vm.realEstateAsset.selected,"rentalUnitId":_vm.rentalUnit.id,"editable":"true","hidePagination":"true"},on:{"close":function($event){_vm.tenant.create = false},"validate":function (tenantId) {
              _vm.tenant.selected = _vm.tenant.list.find(
                function (value) { return value.id === tenantId; }
              );
              _vm.tenant.create = false;
            }}}):_vm._e()],1),(_vm.partner.show)?_c('v-row',{attrs:{"id":"test_select_partner"}},[_c('v-col',{attrs:{"cols":"8"}},[(_vm.partner.show)?_c('v-select',{staticClass:"mt-3",attrs:{"items":_vm.partner.list,"rules":[function () { return !!_vm.partner.selected || 'Sélectionnez un associé'; }],"item-text":"name","item-value":"id","label":"Associé","hint":_vm.categorizationUse.isOptional(_vm.TypeReference.partner)
                ? 'Facultatif'
                : '',"persistent-hint":"","outlined":"","required":"","return-object":""},model:{value:(_vm.partner.selected),callback:function ($$v) {_vm.$set(_vm.partner, "selected", $$v)},expression:"partner.selected"}}):_vm._e()],1)],1):_vm._e(),(_vm.realEstateLoan.show)?_c('v-row',{attrs:{"id":"test_select_realEstateLoan"}},[_c('v-col',{attrs:{"cols":"8"}},[(_vm.realEstateLoan.show)?_c('v-select',{attrs:{"items":_vm.loans,"disabled":!_vm.realEstateAsset.selected ? true : false,"rules":[
              function () { return !!_vm.realEstateLoan.selected || 'Sélectionnez un emprunt'; } ],"item-text":"name","item-value":"id","label":"Emprunt","hint":_vm.categorizationUse.isOptional(_vm.TypeReference.realEstateLoan)
                ? 'Facultatif'
                : '',"persistent-hint":"","outlined":"","required":"","return-object":""},scopedSlots:_vm._u([{key:"append-item",fn:function(){return [_c('v-divider',{staticClass:"mb-2"}),_c('v-btn',{attrs:{"id":"add-credit-transaction","text":"","width":"100%","color":"primary"},on:{"click":function($event){_vm.realEstateLoan.create = true}}},[_c('v-icon',{attrs:{"dense":"","left":""}},[_vm._v("mdi-plus")]),_vm._v(" Ajouter un emprunt ")],1)]},proxy:true}],null,false,3330026419),model:{value:(_vm.realEstateLoan.selected),callback:function ($$v) {_vm.$set(_vm.realEstateLoan, "selected", $$v)},expression:"realEstateLoan.selected"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"4"}})],1):_vm._e(),_c('DialogRight',{attrs:{"dialogModel":_vm.realEstateLoan.create}},[(_vm.realEstateLoan.create)?_c('RealEstateLoanCreate',{attrs:{"productId":_vm.categorizationUse.product.value.id,"isEditable":true,"smallDisplay":true},on:{"close":function($event){_vm.realEstateLoan.create = false},"validate":function($event){_vm.realEstateLoan.create = false}}}):_vm._e()],1),(_vm.supportingDocument.show)?_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[(
              !_vm.accountingPeriodsStore.isLMNP &&
              _vm.subscription &&
              _vm.subscription.plan.type === 'Free'
            )?_c('v-card-text',{staticClass:"card-subscription-free"},[_vm._v(" En période d'essai, vous pouvez ajouter une feuille blanche/une image en tant que justificatif afin de tester la catégorisation. "),_c('br'),_vm._v(" Par la suite, vous devrez mettre à jour votre justificatif pour que votre comptabilité soit correcte ")]):_vm._e(),(_vm.accountingPeriodsStore.isLMNP)?_c('v-card-text',{staticClass:"card-subscription-free"},[_vm._v(" L'ajout de justificatifs est désormais facultatif, mais "),_c('b',[_vm._v("recommandé")]),_vm._v(" pour assurer la conformité avec les exigences du FEC et de la comptabilité d'engagement. ")]):_vm._e(),_c('br'),_c('div',{staticClass:"select-tooltip"},[(_vm.supportingDocument.show)?_c('v-select',{attrs:{"id":"test_select_supporting_document","items":_vm.supportingDocument.list,"rules":[
                function () { return !!_vm.supportingDocument.selected ||
                  'Sélectionnez un justificatif'; } ],"item-text":"name","item-value":"id","label":"Justificatif","persistent-hint":"","outlined":"","required":"","return-object":""},scopedSlots:_vm._u([{key:"append-item",fn:function(){return [_c('v-divider',{staticClass:"mb-2"}),_c('v-btn',{attrs:{"text":"","width":"100%","color":"primary"},on:{"click":function($event){_vm.supportingDocument.create = true}}},[_c('v-icon',{attrs:{"dense":"","left":""}},[_vm._v("mdi-plus")]),_vm._v(" Ajouter un justificatif ")],1)]},proxy:true}],null,false,1510848518),model:{value:(_vm.supportingDocument.selected),callback:function ($$v) {_vm.$set(_vm.supportingDocument, "selected", $$v)},expression:"supportingDocument.selected"}}):_vm._e(),(_vm.accountingPeriodsStore.isIS)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"justify-content: space-between;",attrs:{"color":"#000","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-help-circle-outline")])],1)]}}],null,false,630370518)},[_c('span',[_vm._v("Le justificatif est obligatoire pour établir une comptabilité correcte selon les règles de comptabilité des SCI IS ")])]):_vm._e()],1)],1)],1):_vm._e(),_c('DialogRight',{attrs:{"dialogModel":_vm.supportingDocument.create}},[(_vm.supportingDocument.create)?_c('SupportingDocumentCreate',{attrs:{"smallDisplay":true,"transactionState":_vm.transactionState},on:{"close":function($event){_vm.supportingDocument.create = false},"documentAdded":function (supportingDocumentId) {
              _vm.supportingDocument.selected = _vm.supportingDocument.list.find(
                function (value) { return value.id === supportingDocumentId; }
              );
              _vm.supportingDocument.create = false;
            }}}):_vm._e()],1)],1),(
        _vm.accountingPeriodsStore.isIS &&
        _vm.transactionState.selectedCategory.referenceCounter ===
          _vm.TypeReference.beneficiary
      )?_c('v-row',{attrs:{"id":"test_select_beneficiary"}},[_c('v-col',{attrs:{"cols":"8"}},[(_vm.beneficiary.show)?_c('v-select',{attrs:{"items":_vm.beneficiary.list,"rules":[
            function () { return !!_vm.beneficiary.selected || 'Sélectionnez un bénéficiaire'; } ],"item-text":"name","item-value":"id","label":"Bénéficiaire","persistent-hint":"","outlined":"","required":"","return-object":""},model:{value:(_vm.beneficiary.selected),callback:function ($$v) {_vm.$set(_vm.beneficiary, "selected", $$v)},expression:"beneficiary.selected"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"4"}})],1):_vm._e(),(
        _vm.transactionState.selectedCategory.number === '706000' || // Loyers encaissés
        _vm.transactionState.selectedCategory.number === '164000' || // emprunt : capital emprunté ou remboursé (ANCIEN)
        _vm.transactionState.selectedCategory.number === '164001' // emprunt : capital emprunté ou remboursé
      )?_c('div',[_c('v-row',[_c('v-col',{attrs:{"align-self":"center","cols":"8"}},[_c('h4',{staticClass:"categorizationStep1-title"},[(_vm.transactionState.selectedCategory.number === '706000')?_c('span',[_vm._v(" Le montant du loyer inclut-il les charges locatives ? ")]):_vm._e(),(_vm.transactionState.selectedCategory.number === '164000')?_c('span',[_vm._v(" Le montant comprend-il une part d'intérêts et/ou assurances ? ")]):_vm._e()])]),_c('v-col',{attrs:{"cols":"4"}},[(_vm.transactionState.selectedCategory.number === '706000')?_c('YesNoSwitch',{model:{value:(_vm.withRentalCharge),callback:function ($$v) {_vm.withRentalCharge=$$v},expression:"withRentalCharge"}}):_vm._e(),(_vm.transactionState.selectedCategory.number === '164000')?_c('YesNoSwitch',{model:{value:(_vm.withInterestsAndInsurance),callback:function ($$v) {_vm.withInterestsAndInsurance=$$v},expression:"withInterestsAndInsurance"}}):_vm._e()],1)],1),(_vm.withRentalCharge || _vm.withInterestsAndInsurance)?_c('v-row',[_c('v-col',{attrs:{"align-self":"baseline","cols":"8"}},[_c('h4',{staticClass:"categorizationStep1-title"},[(_vm.transactionState.selectedCategory.number === '706000')?_c('span',[_vm._v(" Quel est le montant de ces charges ? ")]):_vm._e(),(_vm.transactionState.selectedCategory.number === '164000')?_c('span',[_vm._v(" Quel est le montant des intérêts et assurances ? ")]):_vm._e(),(
                _vm.transactionState.selectedCategory.number === '706000' &&
                _vm.rentalUnit &&
                _vm.rentalUnit.taxTvaEnable
              )?_c('span',[_vm._v("(Hors taxe)")]):_vm._e()])]),_c('v-col',{attrs:{"cols":"4"}},[(_vm.transactionState.selectedCategory.number === '706000')?_c('v-text-field',{attrs:{"id":"test_charges_loyer","rules":[
              function () { return Number(_vm.rentalChargeAmountFixed) > 0 ||
                'Les charges doivent être positives'; },
              function () { return Number(_vm.rentalChargeAmountFixed) <
                  _vm.transactionState.transaction.value.amount ||
                'Les charges doivent être inférieures au montant total'; } ],"dense":"","step":"1","outlined":"","placeholder":"Montant des charges","required":"","suffix":"€","type":"number"},model:{value:(_vm.rentalChargeAmountFixed),callback:function ($$v) {_vm.rentalChargeAmountFixed=$$v},expression:"rentalChargeAmountFixed"}}):_vm._e(),(_vm.transactionState.selectedCategory.number === '164000')?_c('v-text-field',{attrs:{"id":"test_interets_assurances","dense":"","step":"1","outlined":"","placeholder":"Montant des intérêts et assurances","required":"","suffix":"€","type":"number"},model:{value:(_vm.interestsAndInsuranceAmountFixed),callback:function ($$v) {_vm.interestsAndInsuranceAmountFixed=$$v},expression:"interestsAndInsuranceAmountFixed"}}):_vm._e()],1)],1):_vm._e(),_c('v-row',[_c('v-divider',{staticClass:"mb-2"})],1)],1):_vm._e(),_c('Next',{ref:"confirmDialog"}),_c('div',{staticClass:"categorizationStep1-btns mt-5"},[_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":function($event){return _vm.validateAttributions()}}},[_vm._v(" Valider ")]),_c('v-btn',{staticClass:"sudivide-btn ml-5",attrs:{"id":"track-subdivide_step1","color":"secondary","depressed":"","text-color":"primary"},on:{"click":_vm.subDivide}},[_vm._v(" Subdiviser ")])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }