import { computed, Ref, ref } from "@vue/composition-api";
import {
  RentalUnit,
  RentalState,
  RentalUsage,
  TaxRateTva,
  AnomalyCode,
  AnomalyOptions,
  TypeReference,
  RealEstateAsset,
} from "@edmp/api";
import { accountingPeriodsStore, realEstateAssetsStore } from "@/store";
import { cloneDeep } from "lodash";

export function useRentalUnit() {
  const rentalUnit: Ref<Partial<RentalUnit>> = ref({});

  const rentalStateList: { text: string; value: RentalState }[] = [
    { text: "Non mis en location", value: RentalState.NOT_RENTAL },
    { text: "Location nue", value: RentalState.RENTAL },
    { text: "Location meublée", value: RentalState.FURNISHED_RENTAL },
  ];
  const rentalUsageList: { text: string; value: RentalUsage }[] = [
    { text: "Habitation", value: RentalUsage.RESIDENTIAL },
    { text: "Professionnel", value: RentalUsage.PROFESSIONAL },
    { text: "Agricole", value: RentalUsage.AGRICULTURAL },
    { text: "Parking", value: RentalUsage.PARKING },
  ];

  const rentalUsageLmnpList: { text: string; value: RentalUsage }[] = [
    {
      text: "Meublé d'habitation longue durée (> 9 mois)",
      value: RentalUsage.LONG_TERM_RENTAL,
    },
    {
      text: "Meublé de tourisme classé",
      value: RentalUsage.CLASSIFIED_HOLIDAY_FURNISHED_RENTAL,
    },
    {
      text: "Meublé de tourisme non classé",
      value: RentalUsage.UNCLASSIFIED_HOLIDAY_FURNISHED_RENTAL,
    },
    {
      text: "Para-hôtelier et chambres d'hôtes",
      value: RentalUsage.SELF_CATERING_ACCOMODATION,
    },
    {
      text: "Résidence gérée - EHPAD",
      value: RentalUsage.MANAGED_RESIDENCE_ASSISTED_ELDERLY_HOME,
    },
    {
      text: "Résidence gérée - Résidence sénior",
      value: RentalUsage.MANAGED_RESIDENCE_ELDERLY_RESIDENCE,
    },
    {
      text: "Résidence gérée - Résidence étudiante",
      value: RentalUsage.MANAGED_RESIDENCE_STUDENT_RESIDENCE,
    },
    {
      text: "Résidence gérée - Résidence d'affaires",
      value: RentalUsage.MANAGED_RESIDENCE_BUSINESS_RESIDENCE,
    },
    {
      text: "Résidence gérée - Résidence de tourisme",
      value: RentalUsage.MANAGED_RESIDENCE_TOURISTIC_RESIDENCE,
    },
  ];

  enum LocationTypes {
    MAINLAND = "Métropole",
    OVERSEAS = "Outre-mer",
    CORSICA = "Corse",
  }

  const taxRateTvaList: { text: string; value: TaxRateTva }[] = [
    { text: "20%", value: TaxRateTva.Normal_20 },
    { text: "10%", value: TaxRateTva.Intermediate_10 },
    { text: "5,5%", value: TaxRateTva["Reduce_5-5"] },
    { text: "2,1%", value: TaxRateTva["Special_2-1"] },
  ];

  const tvaRentalUsageMapping = (
    rentalUsage: { text: string; value: string },
    zip
  ) => {
    let location;
    if (zip.slice(0, 2) === "97" || zip.slice(0, 2) === "98")
      location = LocationTypes.OVERSEAS;
    else if (zip.slice(0, 2) === "20") location = LocationTypes.CORSICA;
    else location = LocationTypes.MAINLAND;

    if (location === LocationTypes.MAINLAND) {
      if (rentalUsage.value === RentalUsage.LONG_TERM_RENTAL) {
        return TaxRateTva.Normal_20;
      }
      if (
        rentalUsage.value === RentalUsage.MANAGED_RESIDENCE_ELDERLY_RESIDENCE ||
        rentalUsage.value === RentalUsage.MANAGED_RESIDENCE_STUDENT_RESIDENCE ||
        rentalUsage.value ===
          RentalUsage.MANAGED_RESIDENCE_BUSINESS_RESIDENCE ||
        rentalUsage.value === RentalUsage.MANAGED_RESIDENCE_TOURISTIC_RESIDENCE
      ) {
        return TaxRateTva.Intermediate_10;
      } else if (rentalUsage.value === RentalUsage.SELF_CATERING_ACCOMODATION) {
        return TaxRateTva.Intermediate_10;
      } else if (
        rentalUsage.value ===
        RentalUsage.MANAGED_RESIDENCE_ASSISTED_ELDERLY_HOME
      ) {
        return TaxRateTva["Reduce_5-5"];
      } else if (
        rentalUsage.value === RentalUsage.UNCLASSIFIED_HOLIDAY_FURNISHED_RENTAL
      ) {
        return TaxRateTva.Normal_20;
      } else if (
        rentalUsage.value === RentalUsage.CLASSIFIED_HOLIDAY_FURNISHED_RENTAL
      ) {
        return TaxRateTva.Intermediate_10;
      }
    } else if (
      location === LocationTypes.OVERSEAS ||
      location === LocationTypes.CORSICA
    ) {
      if (
        rentalUsage.value === RentalUsage.LONG_TERM_RENTAL ||
        rentalUsage.value === RentalUsage.SELF_CATERING_ACCOMODATION ||
        rentalUsage.value ===
          RentalUsage.MANAGED_RESIDENCE_ASSISTED_ELDERLY_HOME ||
        rentalUsage.value === RentalUsage.MANAGED_RESIDENCE_STUDENT_RESIDENCE ||
        rentalUsage.value === RentalUsage.MANAGED_RESIDENCE_ELDERLY_RESIDENCE ||
        rentalUsage.value ===
          RentalUsage.MANAGED_RESIDENCE_BUSINESS_RESIDENCE ||
        rentalUsage.value ===
          RentalUsage.MANAGED_RESIDENCE_TOURISTIC_RESIDENCE ||
        rentalUsage.value === RentalUsage.CLASSIFIED_HOLIDAY_FURNISHED_RENTAL
      ) {
        return TaxRateTva["Special_2-1"];
      } else if (
        rentalUsage.value === RentalUsage.UNCLASSIFIED_HOLIDAY_FURNISHED_RENTAL
      ) {
        return TaxRateTva.Intermediate_10;
      }
    }
  };

  const taxTvaEnableMapping = (rentalUsage: {
    text: string;
    value: string;
  }) => {
    if (
      rentalUsage.value === RentalUsage.LONG_TERM_RENTAL ||
      rentalUsage.value === RentalUsage.UNCLASSIFIED_HOLIDAY_FURNISHED_RENTAL
    ) {
      return false;
    } else return true;
  };

  const taxTvaRule = computed(() => {
    let taxTvaRule: {
      ruleResult: true | string;
      type: "optional" | "required";
    } = { ruleResult: true, type: "optional" };
    if (rentalUnit.value.rentalState === RentalState.NOT_RENTAL) {
      taxTvaRule = {
        ruleResult:
          "Vous ne pouvez pas appliquer la TVA sur ce type de location",
        type: "required",
      };

      rentalUnit.value.taxTvaEnable = false;
    } else {
      if (rentalUnit.value.rentalUsage === RentalUsage.RESIDENTIAL) {
        if (rentalUnit.value.rentalState === RentalState.FURNISHED_RENTAL) {
          taxTvaRule = { ruleResult: true, type: "optional" };
        }
        if (rentalUnit.value.rentalState === RentalState.RENTAL) {
          taxTvaRule = {
            ruleResult:
              "Vous ne pouvez pas appliquer la TVA sur ce type d'usage et de location",
            type: "required",
          };
          rentalUnit.value.taxTvaEnable = false;
        }
      }
      if (rentalUnit.value.rentalUsage === RentalUsage.PROFESSIONAL) {
        if (rentalUnit.value.rentalState === RentalState.FURNISHED_RENTAL) {
          taxTvaRule = {
            ruleResult:
              "L'application de la TVA est obligatoire sur ce type d'usage et de location",
            type: "required",
          };

          rentalUnit.value.taxTvaEnable = true;
        }
        if (rentalUnit.value.rentalState === RentalState.RENTAL) {
          taxTvaRule = { ruleResult: true, type: "optional" };
        }
      }
      if (rentalUnit.value.rentalUsage === RentalUsage.PARKING) {
        taxTvaRule = { ruleResult: true, type: "optional" };
      }
      if (rentalUnit.value.rentalUsage === RentalUsage.AGRICULTURAL) {
        taxTvaRule = { ruleResult: true, type: "optional" };
      }
    }
    return taxTvaRule;
  });

  const anomalyOptions = computed(() => {
    const anomalyOptions: AnomalyOptions = {
      [AnomalyCode.taxTva]: {},
      [AnomalyCode.referenceType]: {},
      [AnomalyCode.objectIdLink]: {},
    };
    if (rentalUnit.value?.id) {
      anomalyOptions[AnomalyCode.taxTva] = {
        referenceId: rentalUnit.value.id,
        params: {
          taxTvaEnable: rentalUnit.value.taxTvaEnable || false,
        },
      };
      anomalyOptions[AnomalyCode.referenceType] = {
        referenceId: rentalUnit.value.id,
        params: {
          rentalUnit: {
            deleted: rentalUnit.value.deleted || false,
          },
        },
      };
      anomalyOptions[AnomalyCode.objectIdLink] = {
        referenceId: rentalUnit.value.id,
        params: {
          rentalUnitDeleted: rentalUnit.value.deleted || false,
        },
      };
    }
    return anomalyOptions;
  });

  const initRentalUnit = async ({
    realEstateAsset,
  }: {
    realEstateAsset: Partial<RealEstateAsset>;
  }) => {
    if (!accountingPeriodsStore.isLMNP) {
      if (realEstateAsset.id) {
        rentalUnit.value = cloneDeep(
          realEstateAssetsStore.getRentalUnitByRealEstateAssetId(
            realEstateAsset.id
          )
        );
      } else {
        rentalUnit.value = {
          name: "",
          productId: realEstateAsset.productId,
        };
      }
    } else {
      if (realEstateAsset.id) {
        rentalUnit.value = cloneDeep({
          ...realEstateAssetsStore.getRentalUnitByRealEstateAssetId(
            realEstateAsset.id
          ),
          rentalState: RentalState.FURNISHED_RENTAL,
        });
      } else {
        rentalUnit.value = {
          name: "",
          productId: realEstateAsset.productId,
          rentalState: RentalState.FURNISHED_RENTAL,
          taxTvaEnable: false,
        };
      }
    }
  };

  const prepareRentalUnitForCreation = ({
    realEstateAsset,
    rentalUsage,
  }: {
    realEstateAsset: Partial<RealEstateAsset>;
    rentalUsage: RentalUsage | undefined;
  }) => {
    if (!rentalUnit.value.name) rentalUnit.value.name = realEstateAsset.name;

    return {
      ...(rentalUnit.value as RentalUnit),
      productId: realEstateAsset.productId as string,
      realEstateAssetId: realEstateAsset.id as string,
      taxRateTVA: rentalUnit.value.taxRateTVA
        ? rentalUnit.value.taxRateTVA
        : TaxRateTva.Normal_20,
      rentalUsage,
    };
  };

  const updateOrCreateRentalUnit = async ({
    realEstateAsset,
    rentalUsage,
  }: {
    realEstateAsset: Partial<RealEstateAsset>;
    rentalUsage: RentalUsage | undefined;
  }) => {
    if (rentalUnit.value) {
      try {
        if (rentalUnit.value.id) {
          rentalUnit.value = await realEstateAssetsStore.updateRentalUnit(
            prepareRentalUnitForCreation({ realEstateAsset, rentalUsage })
          );
        } else {
          rentalUnit.value = await realEstateAssetsStore.createRentalUnit(
            prepareRentalUnitForCreation({ realEstateAsset, rentalUsage })
          );
        }
      } catch (error) {
        console.error("Error[updateOrCreateRentalUnit]", error);
        throw error;
      }
    }
  };

  const initialEquipment = {
    NonHabitable: [
      {
        name: "Cave",
        value: "cave",
        selected: false,
      },
      {
        name: "Parking",
        value: "parking",
        selected: false,
      },
      {
        name: "Garage",
        value: "garage",
        selected: false,
      },
      {
        name: "Balcon",
        value: "balcon",
        selected: false,
      },
      {
        name: "Jardin",
        value: "jardin",
        selected: false,
      },
      {
        name: "Terrasse",
        value: "terrasse",
        selected: false,
      },
      {
        name: "Grenier",
        value: "grenier",
        selected: false,
      },
      {
        name: "Autres",
        value: "autres",
        selected: false,
      },
    ],
    HousingEquipment: [
      {
        name: "Cuisine équipée",
        value: "cuisine",
        selected: false,
      },
      {
        name: "Installation sanitaire",
        value: "sanitaire",
        selected: false,
      },
      {
        name: "Autres",
        value: "autres",
        selected: false,
      },
    ],
    TechEquipment: [
      {
        name: "TV",
        value: "tv",
        selected: false,
      },
      {
        name: "Internet",
        value: "internet",
        selected: false,
      },
      {
        name: "Fibre optique",
        value: "fibre",
        selected: false,
      },
    ],
    CommonEquipment: [
      {
        name: "WC sur le pallier ",
        value: "wc",
        selected: false,
      },
      {
        name: "Douche sur le pallier",
        value: "douche",
        selected: false,
      },
      {
        name: "Service de gardiennage",
        value: "jardin_service",
        selected: false,
      },
      {
        name: "Ascenseur",
        value: "ascenseur",
        selected: false,
      },
      {
        name: "Local à vélo",
        value: "velo",
        selected: false,
      },
      {
        name: "Autres",
        value: "autres",
        selected: false,
      },
    ],
  };

  return {
    rentalUnit,
    initRentalUnit,
    updateOrCreateRentalUnit,
    prepareRentalUnitForCreation,
    rentalStateList,
    rentalUsageList,
    rentalUsageLmnpList,
    taxTvaRule,
    taxRateTvaList,
    TaxRateTva,
    tvaRentalUsageMapping,
    taxTvaEnableMapping,
    TypeReference,
    anomalyOptions,
    initialEquipment,
  };
}
