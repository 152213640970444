
















































































































































































































































































































































import DialogRight from "@/components/core/DialogRight.vue";
import RealEstateAssetUpdate from "@/components/core/rent/RealEstateAssetUpdate.vue";
import RentalAgreementUpdate from "@/components/core/rent/RentalAgreementUpdate.vue";
import { RealEstateAsset, RentalAgreement } from "@edmp/api";
import { computed, defineComponent, Ref, ref } from "@vue/composition-api";
import ToggleButton from "@/components/atom/ToggleButton.vue";
import moment from "moment";
import { CustomLabelButton } from "@/components/atom/button";
import { BadgeNew } from "@/components/atom/badge";
import { ability } from "@/services";
import { activitiesStore, coreStore } from "@/store";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";
import { VForm } from "@/models";
import { ForbiddenError, subject } from "@casl/ability";
import { ROUTE_NAMES } from "@/router/routes";
import { YesNoSwitch } from "@/components/atom/switch";
import RentalAgreementsPreviewMail from "@/components/core/realEstate/RentalAgreementsPreviewMail.vue";

const DELAY_BEFORE_SEND_MAIL = Array.from(Array(20).keys())
  .map((i) => {
    const nbDay = i + 1;
    return {
      value: nbDay,
      text: `${nbDay} jour${nbDay > 1 ? "s" : ""} avant`,
    };
  })
  .reverse();
const REMINDER_AFTER_SEND_MAIL = Array.from(Array(20).keys())
  .map((i) => {
    const nbDay = i + 1;
    return {
      value: nbDay,
      text: `${nbDay} jour${nbDay > 1 ? "s" : ""} après`,
    };
  })
  .reverse();
const DAY_OF_THE_MONTH = Array.from(Array(28).keys()).map((i) => {
  const nbDay = i + 1;
  return {
    value: nbDay,
    text: `${nbDay.toLocaleString("fr-FR", {
      minimumIntegerDigits: 2,
    })} du mois`,
  };
});

export default defineComponent({
  name: "RentReceipt",
  components: {
    DialogRight,
    RentalAgreementUpdate,
    RealEstateAssetUpdate,
    ToggleButton,
    CustomLabelButton,
    BadgeNew,
    YesNoSwitch,
    RentalAgreementsPreviewMail,
  },
  props: {
    productId: {
      type: String,
      required: true,
    },
    realEstateAsset: {
      type: Object as () => RealEstateAsset,
      required: true,
    },
    rentalAgreement: {
      type: Object as () => RentalAgreement &
        Required<Pick<RentalAgreement, "options">>,
      required: true,
    },
    validateInProgress: {
      type: Boolean,
      default: false,
    },
    hidePagination: {
      type: Boolean,
      default: false,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const { rentalAgreement } = props;
    const openPreviewModalRentReceipt: Ref<boolean> = ref(false);
    const openPreviewModalRentCall: Ref<boolean> = ref(false);
    const openPreviewModalUnpaidRentCall: Ref<boolean> = ref(false);

    const activityHasSignature = computed(() => {
      return (
        !!activitiesStore.currentActivity?.signature?.data ||
        !!activitiesStore.currentActivity?.signature?.signatureId
      );
    });
    const activityHasLogo = computed(() => {
      return !!activitiesStore.currentActivity?.logoId;
    });

    async function validate() {
      if ((context.refs.form as VForm).validate()) {
        context.emit("validate");
      }
    }
    function closePreviewModal() {
      openPreviewModalRentReceipt.value = false;
      openPreviewModalRentCall.value = false;
      openPreviewModalUnpaidRentCall.value = false;
    }
    function checkPermissions() {
      try {
        ForbiddenError.from(ability).throwUnlessCan(
          "manageTenantServices",
          subject("RentalManagement", {})
        );
      } catch (error) {
        if (error instanceof ForbiddenError) {
          coreStore.displayFeedback({
            type: FeedbackTypeEnum.WARNING,
            message: error.message,
          });
          rentalAgreement.options.rentCallActivated = false;
          rentalAgreement.options.rentReceiptActivated = false;
          rentalAgreement.options.reminderUnpaidRentActivated = false;
        }
      }
    }

    return {
      moment,
      DELAY_BEFORE_SEND_MAIL,
      DAY_OF_THE_MONTH,
      REMINDER_AFTER_SEND_MAIL,
      validate,
      checkPermissions,
      ROUTE_NAMES,
      activityHasSignature,
      openPreviewModalRentReceipt,
      openPreviewModalRentCall,
      openPreviewModalUnpaidRentCall,
      closePreviewModal,
      activityHasLogo,
    };
  },
});
