





























































































































































































































import { HelpingMessage } from "@/components/atom/helping";
import DialogRight from "@/components/core/DialogRight.vue";
import TaskCard from "@/components/core/tasks/task/TaskCard.vue";
import { ArticleEnum, useCrisp } from "@/composables/crisp.usable";
import router from "@/router";
import { ROUTE_NAMES } from "@/router/routes";
import { ability } from "@/services";
import {
  accountingPeriodsStore,
  productsStore,
  coreStore,
  tasksStore,
  transactionsStore,
  tenantsStore,
} from "@/store";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";
import { FilterKeys } from "@/utils";
import { ForbiddenError, subject } from "@casl/ability";
import { TaxRegime, TaskGroupCode, TaskStatus, ProductsModel } from "@edmp/api";
import {
  computed,
  defineComponent,
  onBeforeMount,
  PropType,
  ref,
  watch,
} from "@vue/composition-api";
import ExpenseReportCreate from "./create/ExpenseReportCreate.vue";
import TransactionAmortizationCreate from "./create/TransactionAmortizationCreate.vue";
import TransactionImportCreate from "./create/TransactionImportCreate.vue";
import TransactionManualCreate from "./create/TransactionManualCreate.vue";
import Transaction from "./transaction/Transaction.vue";
import { useTransactions } from "./transactions.usable";
import TransactionsFiltersDisplay from "./TransactionsFiltersDisplay.vue";
import TransactionsFiltersDrawer from "./TransactionsFiltersDrawer.vue";
import TransactionsHeader from "./TransactionsHeader.vue";
import TransactionDuplicateCreate from "./create/TransactionDuplicateCreate.vue";
import BankAccountsLmnpModal from "../activities/bankAccounts/BankAccountsLmnpModal.vue";

export default defineComponent({
  name: "Transactions",
  components: {
    TransactionsHeader,
    HelpingMessage,
    Transaction,
    TransactionManualCreate,
    TransactionAmortizationCreate,
    ExpenseReportCreate,
    TransactionsFiltersDrawer,
    TransactionsFiltersDisplay,
    DialogRight,
    TransactionImportCreate,
    TaskCard,
    TransactionDuplicateCreate,
    BankAccountsLmnpModal,
  },
  props: {
    openManualTransaction: { type: String as PropType<"true"> },
    openModalAmortization: { type: String as PropType<"true"> },
  },
  setup(props) {
    const { removeFilter, updateCategoriesList } = useTransactions();

    const product = computed(() => productsStore.currentProduct);

    const isLoading = computed(() => transactionsStore.loading);
    const readonly = computed(() =>
      accountingPeriodsStore.isIR
        ? tasksStore.isCurrentAccountingPeriodFillCerfa2072Completed
        : accountingPeriodsStore.currentAccountingPeriod?.closed
    );

    const filtersEnabled = computed(
      () => Object.keys(transactionsStore.filters).length !== 0
    );
    const isLmnpWithBankAccount =
      product.value?.type === ProductsModel.ProductTypes.LMNP &&
      product.value?.dedicatedBankAccount;
    const transactionsTask = computed(() => {
      const taskGroup = tasksStore.taskGroups.find(
        (group) => group.groupCode === TaskGroupCode.Transactions
      );

      const isNotCompleted =
        taskGroup && taskGroup.status !== TaskStatus.COMPLETED;

      return isNotCompleted && !isLmnpWithBankAccount
        ? taskGroup.tasksLocal[0]
        : null;
    });

    const transactions = computed(
      () => transactionsStore.filteredTransactionsGroupByDate
    );
    const isOpenModalAmortization = ref(Boolean(props.openModalAmortization));

    const openArticle = () =>
      useCrisp().openArticle(ArticleEnum.CATEGORIZE_TRANSACTION);

    const isDedicatedBankAccountRequired = computed(
      () =>
        product.value?.type === ProductsModel.ProductTypes.LMNP &&
        product.value?.dedicatedBankAccount === undefined
    );

    // Modal
    const isOpenAddManualTransaction = ref(false);
    const isOpenAddExpenseReport = ref(false);
    const isOpenBankAccountsLmnp = ref(isDedicatedBankAccountRequired.value);

    const isOpenImportManualTransactions = ref(false);
    watch(
      () => isOpenImportManualTransactions.value,
      (isOpen) => {
        if (isOpen) {
          try {
            ForbiddenError.from(ability).throwUnlessCan(
              "import",
              subject("Transaction", {})
            );
            isOpenImportManualTransactions.value = isOpen;
          } catch (error) {
            if (error instanceof ForbiddenError) {
              coreStore.displayFeedback({
                type: FeedbackTypeEnum.WARNING,
                message: error.message,
              });
            }
            isOpenImportManualTransactions.value = false;
          }
        } else {
          isOpenImportManualTransactions.value = isOpen;
        }
      },
      { deep: true }
    );

    const isOpenFilterTransactions = ref(false);

    // /Modal

    // Use for add solde M-1
    watch(
      () => props,
      () =>
        (isOpenAddManualTransaction.value =
          props.openManualTransaction === "true"),
      { deep: true }
    );

    // Duplication
    const isOpenDuplicate = ref(false);
    const transactionSourceId = ref();
    const openDuplication = (transactionId: string) => {
      isOpenDuplicate.value = true;
      transactionSourceId.value = transactionId;
    };
    watch(
      () => isOpenDuplicate.value,
      () => {
        if (!isOpenDuplicate.value) {
          transactionsStore.fetchTransactions({
            productId: productsStore.currentId,
            accountingPeriodId: accountingPeriodsStore.currentId,
          });
        }
      }
    );

    onBeforeMount(async () => {
      await tenantsStore.fetchTenants({
        productId: productsStore.currentId,
      });
      transactionsStore.fetchTransactions({
        productId: productsStore.currentId,
        accountingPeriodId: accountingPeriodsStore.currentId,
      });

      await updateCategoriesList();

      // Use for add solde M-1
      isOpenAddManualTransaction.value = props.openManualTransaction === "true";
      if (
        router.currentRoute.query.resumptionAmortization === "true" &&
        (accountingPeriodsStore.currentAccountingPeriod?.taxRegime ===
          TaxRegime.IS_2065 ||
          accountingPeriodsStore.currentAccountingPeriod?.taxRegime ===
            TaxRegime.LMNP_2031)
      ) {
        isOpenModalAmortization.value = true;
      }
    });

    return {
      isLoading,
      readonly,
      filtersEnabled,
      FilterKeys,
      transactionsTask,
      isLmnpWithBankAccount,
      transactions,
      isOpenFilterTransactions,
      isOpenAddManualTransaction,
      isOpenAddExpenseReport,
      isOpenImportManualTransactions,
      isOpenModalAmortization,
      isOpenBankAccountsLmnp,
      removeFilter,
      openArticle,
      ROUTE_NAMES,
      openDuplication,
      isOpenDuplicate,
      transactionSourceId,
      accountingPeriodsStore,
    };
  },
});
