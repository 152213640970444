

















































































































import { HelpingMessage } from "@/components/atom/helping";
import AccountingPeriodChoice from "@/components/core/accounting-period/AccountingPeriodChoice.vue";
import Confirm from "@/components/core/modals/Confirm.vue";
import TaskGroups from "@/components/core/tasks/TaskGroups.vue";
import { ArticleEnum, useCrisp } from "@/composables/crisp.usable";
import { TaskLocal, VConfirmDialog } from "@/models";
import router from "@/router";
import { ROUTE_NAMES } from "@/router/routes";
import {
  accountingCarryForwardsStore,
  accountingPeriodsStore,
  assembliesStore,
  coreStore,
  documentsStore,
  partnersStore,
  productsStore,
  realEstateAssetsStore,
  rentalBuildingsStore,
  tasksStore,
  transactionsStore,
} from "@/store";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";
import { downloadFileFromUrl } from "@/utils";
import {
  AccountingCarryForwardLineType,
  AccountingPeriod,
  getReferredIdByTypeWithReferences,
  OmitField,
  TasksService,
  TaskTypeReference,
  TaskCode,
  TaxRegime,
} from "@edmp/api";
import {
  computed,
  defineComponent,
  onBeforeMount,
  Ref,
  ref,
  watch,
} from "@vue/composition-api";

import EventsYearEndModal from "./EventsYearEndModal.vue";
import TitleNew from "../../title/TitleNew.vue";

export default defineComponent({
  name: "EventsYearEnd",
  components: {
    AccountingPeriodChoice,
    TaskGroups,
    HelpingMessage,
    Confirm,
    EventsYearEndModal,
    TitleNew,
  },
  setup(props, context) {
    const taskGroups = computed(() => {
      return tasksStore.closureTaskGroup;
    });

    const definitionsYearsEndPeriod: Ref<AccountingPeriod> = ref(
      {} as AccountingPeriod
    );

    async function getDefinitionsYearsEndPeriod(): Promise<void> {
      definitionsYearsEndPeriod.value =
        (accountingPeriodsStore.currentAccountingPeriod ||
          {}) as AccountingPeriod;
    }

    const closeModal = () => {
      tasksStore.closeYearEndModal();
    };

    const resolveTask = async (
      task: TaskLocal,
      data?: OmitField<Required<TasksService.ValidateIn>, "id">
    ) => {
      const assemblyId = getReferredIdByTypeWithReferences(
        task.references,
        TaskTypeReference.assembly
      );
      if (
        task.code === TaskCode.TransactionsCategorized &&
        transactionsStore.numberOfTransactionsToBeCategorized
      ) {
        coreStore.displayFeedback({
          type: FeedbackTypeEnum.ERROR,
          message: "Catégorisez vos transactions avant de valider la tâche",
        });
      } else if (
        task.code === TaskCode.SupportingDocuments &&
        accountingPeriodsStore.currentAccountingPeriod?.taxRegime ===
          TaxRegime.IR_2072
      ) {
        await tasksStore.validateTask({
          taskLocal: task,
        });
        coreStore.displayFeedback({
          type: FeedbackTypeEnum.FEEDBACK,
          message:
            "2 nouveaux documents ont été générés par Ownily et sont disponibles dans l'espace Mes documents",
          timeout: 8000,
        });
      } else if (
        [
          TaskCode.BalanceSimple,
          TaskCode.ViewAndControlResultsAccount,
        ].includes(task.code)
      ) {
        await tasksStore.validateTask({
          taskLocal: task,
        });
        coreStore.displayFeedback({
          type: FeedbackTypeEnum.FEEDBACK,
          message:
            "1 nouveau document a été généré par Ownily et est disponible dans l'espace Mes documents",
          timeout: 8000,
        });
      } else if (
        [
          TaskCode.FillCerfa2033,
          TaskCode.FillCerfa2031,
          TaskCode.FillCerfa2065,
          TaskCode.FillCerfa2072,
        ].includes(task.code)
      ) {
        if (
          task.code === TaskCode.FillCerfa2033 &&
          accountingPeriodsStore.isIS
        ) {
          await accountingCarryForwardsStore.fetchAccountingCarryForwards();
          const carryForwardRecovery =
            accountingCarryForwardsStore.getAccountingCarryForwardRecovery;
          if (
            carryForwardRecovery &&
            carryForwardRecovery.lines.some(
              (line) =>
                line.type === AccountingCarryForwardLineType.TOTAL_DEFICIT
            )
          ) {
            router.push({
              name: ROUTE_NAMES.TaxDeclaration2033,
              query: {
                openAddDeferrableDeficit: "true",
              },
            });
          }
        }
        const openConfirm: boolean = await (
          context.refs.confirmDialog as VConfirmDialog
        ).open(
          "Cette action est définitive, aucune modification ne pourra être appliquée sur vos transactions. Êtes-vous sûr de vouloir valider ?"
        );

        if (openConfirm) {
          await tasksStore.validateTask({
            taskLocal: task,
            data,
          });
          coreStore.displayFeedback({
            type: FeedbackTypeEnum.FEEDBACK,
            message:
              "1 nouveau document a été généré par Ownily et est disponible dans l'espace Mes documents",
            timeout: 8000,
          });
        }
      } else if (task.code === TaskCode.FillFec) {
        const openConfirm: boolean = await (
          context.refs.confirmDialog as VConfirmDialog
        ).open(
          "Cette action est définitive, aucune modification ne pourra être appliquée sur vos transactions. Êtes-vous sûr de vouloir valider ?"
        );

        if (openConfirm) {
          await tasksStore.validateTask({
            taskLocal: task,
          });
          coreStore.displayFeedback({
            type: FeedbackTypeEnum.FEEDBACK,
            message:
              "1 nouveau document a été généré par Ownily et est disponible dans l'espace Mes documents",
            timeout: 8000,
          });
          router.push({ name: ROUTE_NAMES.Documents });
        }
      } else if (task.code === TaskCode.SendPartnersResults) {
        await tasksStore.validateTask({
          taskLocal: task,
        });
        const nbPartners = partnersStore.partners.length;
        coreStore.displayFeedback({
          type: FeedbackTypeEnum.FEEDBACK,
          message: `${nbPartners} nouveaux documents ont été générés par Ownily et sont disponibles dans l'espace Mes documents`,
          timeout: 8000,
        });
      } else if (task.code === TaskCode.BuildingConfigured) {
        const rentalBuildings = await rentalBuildingsStore.fetchRentalBuildings(
          {
            accountingPeriodId: accountingPeriodsStore.currentId,
          }
        );
        const realEstateAssetsNumber = rentalBuildings.reduce(
          (previousValue, currentValue) => {
            return previousValue + currentValue.realEstateAssetIds.length;
          },
          0
        );
        if (
          realEstateAssetsNumber !==
          realEstateAssetsStore.realEstateAssets.length
        ) {
          coreStore.displayFeedback({
            type: FeedbackTypeEnum.ERROR,
            message: "Il reste des biens à affecter à vos immeubles.",
          });
        } else {
          await tasksStore.validateTask({
            taskLocal: task,
          });
        }
      } else if (
        task.code === TaskCode.AmortizationsResumptionPostAmortizationsUpdate
      ) {
        router.push({
          name: ROUTE_NAMES.Transactions,
          query: {
            openModalAmortization: "true",
          },
        });
      } else if (
        [TaskCode.InviteYearEnd, TaskCode.ReportYearEnd].includes(task.code) &&
        assemblyId
      ) {
        const event = assembliesStore.getGeneralAssemblyEvent(assemblyId);
        if (!event) {
          coreStore.displayFeedback({
            type: FeedbackTypeEnum.ERROR,
            message:
              "L'assemblée générale n'a pas été récupérée, veuillez contacter le support.",
          });
          return;
        }

        if (task.code === TaskCode.InviteYearEnd) {
          const partnersList = computed(() => partnersStore.partners);
          const generalAssemblyEvent = {
            ...event,
            invitation: partnersList.value.map((p) => p.id),
          };
          const assemblyUpdated = await assembliesStore.invite(
            generalAssemblyEvent
          );

          await documentsStore.fetchDocuments();
          tasksStore.fetchTaskGroups({});
          coreStore.displayFeedback({
            type: FeedbackTypeEnum.FEEDBACK,
            message:
              "Un nouveau document a été généré par Ownily et est disponible dans l'espace Mes documents ou dans la section document du détail de votre Assemblée Générale",
            timeout: 8000,
          });

          const document = assemblyUpdated.document;
          if (document?.href) {
            downloadFileFromUrl(document.href, document.name ?? "Convocations");
          }
        } else if (task.code === TaskCode.ReportYearEnd) {
          router.push({
            name: ROUTE_NAMES.EventsGeneralAssemblyDetails,
            params: {
              productId: productsStore.currentId,
              id: assemblyId,
            },
            query: {
              openAgPVModal: "true",
            },
          });
        }
      } else if (task.code === TaskCode.TeletransmitMyTaxReturn) {
        const openConfirm: boolean = await (
          context.refs.confirmDialog as VConfirmDialog
        ).open(
          "Votre déclaration va être envoyée au centre des impôts, vous devrez attendre une réponse ou contacter le support pour effectuer une modification. Êtes-vous sûr de vouloir valider ?"
        );

        if (openConfirm) {
          await tasksStore.validateTask({
            taskLocal: task,
          });
          coreStore.displayFeedback({
            type: FeedbackTypeEnum.FEEDBACK,
            message: "Votre déclaration à été envoyé",
            timeout: 8000,
          });
        }
      } else {
        await tasksStore.validateTask({
          taskLocal: task,
        });
      }
    };

    const closedAt = computed(() => {
      const task = tasksStore.completedTasks.find((task) => {
        const isMatchingAccountingPeriod =
          getReferredIdByTypeWithReferences(
            task.references,
            TaskTypeReference.accountingPeriod
          ) === accountingPeriodsStore.currentId;

        if (accountingPeriodsStore.isIR) {
          return (
            task.code === TaskCode.ReportYearEnd && isMatchingAccountingPeriod
          );
        } else {
          return (
            task.code === TaskCode.TeletransmitMyTaxReturn &&
            isMatchingAccountingPeriod
          );
        }
      });

      return task?.completedAt;
    });
    const init = async () => {
      await getDefinitionsYearsEndPeriod();
      await transactionsStore.updateCategoriesList();
      await rentalBuildingsStore.fetchRentalBuildings({
        accountingPeriodId: accountingPeriodsStore.currentId,
      });
      await tasksStore.fetchTaskGroups({});
    };

    watch(
      () => accountingPeriodsStore.currentAccountingPeriod,
      () => init(),
      {
        deep: true,
      }
    );
    onBeforeMount(async () => await init());

    return {
      taskGroups,
      resolveTask,
      getDefinitionsYearsEndPeriod,
      definitionsYearsEndPeriod,
      isClosed: computed(
        () => !!accountingPeriodsStore.currentAccountingPeriod?.closed
      ),
      closedAt,
      isIS: computed(() => accountingPeriodsStore.isIS),
      openArticle: () => {
        let taxRegimeArticleMap = new Map([
          [TaxRegime.IR_2072, ArticleEnum.EVENTS_YEAR_END],
          [TaxRegime.IS_2065, ArticleEnum.EVENTS_YEAR_END_IS],
        ]);
        const taxRegime =
          accountingPeriodsStore.currentAccountingPeriod?.taxRegime ??
          TaxRegime.IR_2072;

        useCrisp().openArticle(
          taxRegimeArticleMap.get(taxRegime) ?? ArticleEnum.EVENTS_YEAR_END
        );
      },
      showYearEndModal: computed(() => tasksStore.showYearEndModal),
      closeModal,
      ROUTE_NAMES,
    };
  },
});
