

















































































































































































































































































































































































import {
  ref,
  reactive,
  PropType,
  onBeforeMount,
  computed,
  Ref,
  watch,
} from "@vue/composition-api";
import { countDecimals } from "@/utils";
import {
  NewRealEstateLoan,
  RealEstateLoan,
  LoanType,
  InsuranceAmountType,
  getMonthlyPayment,
  getInsurancePayment,
  round2decimals,
  AnomalyCode,
  AnomalyOptions,
  isLoanTypeAutomatized,
  getMoment,
} from "@edmp/api";

import { defineComponent } from "@vue/composition-api";
import {
  productsStore,
  coreStore,
  realEstateLoansStore,
  accountingPeriodsStore,
  realEstateAssetsStore,
} from "@/store";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";
import { format } from "date-fns";

import { VForm } from "@/models";
import { cloneDeep } from "lodash";

import RealEstateLoans from "@/components/core/activities/realEstateLoans/RealEstateLoans.vue";
import { YesNoSwitch } from "@/components/atom/switch";
import Anomalies from "../../anomalies/Anomalies.vue";
import DatePicker from "@/components/atom/DatePicker.vue";
import i18n from "@/plugins/i18n";

export const defaultLoan: Partial<NewRealEstateLoan> = {
  loanType: LoanType.DEPRECIABLE,
  realEstateAssetId: "",
  name: "",
  insuranceAmountType: InsuranceAmountType.PERCENT_BORROWED,
  insuranceIncludedInLoan: true,
  // Tips : Set key to undefined for reactivity in Compute method
  loanStartAt: "",
  loanPeriodInMonths: undefined,
  loanAmount: undefined,
  rateExcludedInsurance: undefined,
  insuranceAmount: undefined,
};

export default defineComponent({
  name: "RealEstateLoanModal",
  components: { RealEstateLoans, YesNoSwitch, Anomalies, DatePicker },
  props: {
    selectedLoan: {
      // We use defaultLoan at Creation
      type: Object as PropType<RealEstateLoan>,
      default: () => ({ ...defaultLoan, productId: productsStore.currentId }),
    },
    smallDisplay: {
      type: Boolean,
      default: false,
    },
    closeDisabled: {
      type: Boolean,
      default: false,
    },
    realEstateAssetId: { type: String, required: false },
    isEditing: { type: Boolean, default: false },
  },

  setup(props, context) {
    //RentalBuilding
    const isLoadingValidate = ref(false);
    const submitType = ref("");
    const realEstateLoan = reactive<RealEstateLoan>(
      cloneDeep(props.selectedLoan)
    );
    const realEstateAssets = computed(() =>
      realEstateAssetsStore.realEstateAssets.map((realEstateAsset) => {
        const temp = {
          text: `${realEstateAsset.name}`,
          value: realEstateAsset.id,
          disabled: false,
          alert: false,
        };
        if (
          realEstateAsset.boughtAt &&
          getMoment(realEstateAsset.boughtAt) >
            getMoment(accountingPeriodsStore.currentAccountingPeriod?.endAt)
        ) {
          temp.alert = true;
        }
        return temp;
      })
    );
    const realEstateAssetIdsSelected: Ref<string[]> = ref([
      props.selectedLoan.realEstateAssetId,
    ]);
    const selectedRealEstateAssetRules = (): {
      valid: boolean;
      name: string;
      message: string;
    } => {
      if (
        !(
          realEstateAssetIdsSelected.value &&
          realEstateAssetIdsSelected.value.length
        )
      ) {
        return {
          valid: false,
          name: "EMPTY",
          message: "Le champ ne peut pas être vide",
        };
      } else {
        const realEstateAssetsNotAssignedWithWrongDateSelected =
          realEstateAssetsStore.realEstateAssets.filter((realEstateAsset) => {
            if (
              realEstateAssetIdsSelected.value &&
              realEstateAssetIdsSelected.value.includes(realEstateAsset.id) &&
              realEstateAsset.boughtAt &&
              getMoment(realEstateAsset.boughtAt) >
                getMoment(accountingPeriodsStore.currentAccountingPeriod?.endAt)
            ) {
              return true;
            }
            return false;
          });

        if (realEstateAssetsNotAssignedWithWrongDateSelected.length) {
          return {
            valid: false,
            name: "WRONG DATE",
            message: `La date d'achat du bien "${realEstateAssetsNotAssignedWithWrongDateSelected[0].name}" est plus récente que l'année d'exercice.`,
          };
        }
      }

      if (realEstateAssetIdsSelected.value.length > 2) {
        return {
          valid: false,
          name: "ONE REAL ESTATE ASSET",
          message: `Un seul bien aurorisé`,
        };
      }

      return { valid: true, name: "OK", message: "Aucune erreur" };
    };

    const isEditingLocal = ref(props.isEditing);
    const isCreating = ref(!props.selectedLoan.id);

    const today = ref(format(new Date(), "yyyy-MM-dd"));
    const modalTitle = computed(() =>
      isCreating.value
        ? "Ajouter un emprunt"
        : props.selectedLoan.id
        ? `Emprunt - ${props.selectedLoan.name}`
        : `Modifier - ${props.selectedLoan.name}`
    );

    // LoanAmount
    const formattedLoanAmount = computed(() =>
      realEstateLoan.loanAmount ? i18n.n(realEstateLoan.loanAmount) : ""
    );
    const updateLoanAmount = (value) => {
      realEstateLoan.loanAmount = parseFloat(value) || 0;
    };

    const loanTypeList: {
      text: string;
      value: string;
    }[] = [
      { text: "Amortissable ", value: LoanType.DEPRECIABLE },
      { text: "In Fine", value: LoanType.REFUNDABLE },
      { text: "Autre (à palier, différé...)", value: LoanType.OTHER },
    ];

    const loanInsuranceTypeList: {
      text: string;
      value: string;
    }[] = [
      {
        text: "Pourcentage du capital emprunté",
        value: InsuranceAmountType.PERCENT_BORROWED,
      },
      {
        text: "Pourcentage du capital restant dû",
        value: InsuranceAmountType.PERCENT_BALANCE,
      },
      { text: "Montant Fixe", value: InsuranceAmountType.FIXED },
    ];

    const anomalyOptions = computed(() => {
      const anomalyOptions: AnomalyOptions = { [AnomalyCode.loan]: {} };
      if (realEstateLoan.id) {
        anomalyOptions[AnomalyCode.loan] = {
          referenceId: realEstateLoan.id,
          params: {
            insuranceEnable: realEstateLoan.insuranceIncludedInLoan || false,
          },
        };
      }
      return anomalyOptions;
    });

    const getInsuranceAmountIcon = () => {
      if (realEstateLoan.insuranceAmountType === InsuranceAmountType.FIXED) {
        return "mdi-currency-eur";
      } else {
        return "mdi-percent-outline";
      }
    };

    const toggleInsuranceAmountType = () => {
      if (
        realEstateLoan.insuranceAmountType ===
        InsuranceAmountType.PERCENT_BORROWED
      ) {
        realEstateLoan.insuranceAmountType = InsuranceAmountType.FIXED;
      } else if (
        realEstateLoan.insuranceAmountType === InsuranceAmountType.FIXED
      ) {
        realEstateLoan.insuranceAmountType =
          InsuranceAmountType.PERCENT_BALANCE;
      } else {
        realEstateLoan.insuranceAmountType =
          InsuranceAmountType.PERCENT_BORROWED;
      }
    };

    // Computed data
    const monthlyPayment = computed(() => {
      return round2decimals(getMonthlyPayment(realEstateLoan));
    });
    const insurancePayment = computed(() =>
      realEstateLoan.loanAmount !== undefined
        ? round2decimals(getInsurancePayment(realEstateLoan))
        : undefined
    );

    async function validate() {
      const realEstateAssetId = props.realEstateAssetId
        ? props.realEstateAssetId
        : realEstateAssetIdsSelected.value.filter(
            (realEstateAssetId) => realEstateAssetId != ""
          )[0];

      if (
        (context.refs.form as VForm).validate() &&
        realEstateAssetId !== undefined
      ) {
        try {
          if (props.selectedLoan.id) {
            await realEstateLoansStore.updateRealEstateLoan({
              ...realEstateLoan,
            });
          } else {
            await realEstateLoansStore.createRealEstateLoan({
              ...realEstateLoan,
              realEstateAssetId,
              productId: productsStore.currentId,
            });
          }
          context.emit("validate");
        } catch (err) {
          console.log(err);
          const message =
            "Une erreur est survenue lors de la création de votre crédit";
          coreStore.displayFeedback({
            message,
            type: FeedbackTypeEnum.ERROR,
          });
        }
      }
    }

    watch(
      () => props.selectedLoan,
      (newLoan) => {
        Object.assign(realEstateLoan, cloneDeep(newLoan));
        isCreating.value = !props.selectedLoan.id;
        isEditingLocal.value = true;
      },
      { deep: true }
    );

    function closeDialog() {
      context.emit("close");
    }

    onBeforeMount(() => {
      if (isCreating.value) {
        isEditingLocal.value = true;
      }
    });

    return {
      validate,
      closeDialog,
      loanTypeList,
      loanInsuranceTypeList,
      isCreating,
      isEditingLocal,
      submitType,
      today,
      realEstateLoan,
      modalTitle,
      monthlyPayment,
      insurancePayment,
      formattedLoanAmount,
      updateLoanAmount,
      getInsuranceAmountIcon,
      toggleInsuranceAmountType,
      countDecimals,
      isLoanTypeAutomatized,
      anomalyOptions,
      isLoadingValidate,
      realEstateAssets,
      selectedRealEstateAssetRules,
      realEstateAssetIdsSelected,
    };
  },
});
