































































































































































































































































































































































import { computed, defineComponent, PropType } from "@vue/composition-api";
import {
  CategorizationEntry,
  CheckNewAnomaliesType,
  LedgerAccountEnum,
} from "@edmp/api";

import { countDecimals } from "@/utils";

import { useCategorization } from "./categorization.usable";
import { TransactionState } from "../transaction/transaction.usable";

import Anomalies from "../../anomalies/Anomalies.vue";
import { realEstateAssetsStore } from "@/store";

export default defineComponent({
  name: "CategorizationStep2",
  components: {
    Anomalies,
  },
  props: {
    transactionState: {
      type: Object as PropType<TransactionState>,
      required: true,
    },
    isOpenRentReceiptStep: { type: Number, required: true },
  },
  setup(props, context) {
    const categorizationUse = computed(() =>
      useCategorization(props.transactionState, context)
    );
    const hasCharges = computed(() => {
      const charges = props.transactionState.lines.find(
        (line) => line.account === LedgerAccountEnum.N708399
      );
      if (charges) {
        return true;
      }
      return false;
    });

    const rentalUnit = computed(() =>
      realEstateAssetsStore.getRentalUnit(
        (
          props.transactionState.lines.find(
            (line) => line.rentalUnit
          ) as CategorizationEntry
        ).rentalUnit as string
      )
    );

    return {
      categorizationUse,
      CheckNewAnomaliesType,
      countDecimals,
      hasCharges,
      rentalUnit,
    };
  },
});
