








































































import { computed, defineComponent, Ref, ref } from "@vue/composition-api";
import { RealEstateAsset, TenantTypeEnum } from "@edmp/api";

import { VForm } from "@/models";
import { realEstateAssetsStore, tenantsStore } from "@/store";

export default defineComponent({
  name: "TenantCreate",
  props: {
    productId: {
      type: String,
      required: true,
    },
    realEstateAsset: {
      type: Object as () => RealEstateAsset,
      required: true,
    },
    rentalUnitId: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const firstName = ref("");
    const lastName = ref("");
    const phone = ref("");

    const validateInProgress: Ref<boolean> = ref(false);

    function resetForm() {
      firstName.value = "";
      lastName.value = "";
      phone.value = "";
      (context.refs.form as VForm).resetValidation();
    }

    async function validate() {
      validateInProgress.value = true;
      if ((context.refs.form as VForm).validate()) {
        try {
          const tenant = await tenantsStore.createTenant({
            productId: props.productId,
            realEstateAssetId: props.realEstateAsset.id,
            rentalAgreementId: "",
            type: TenantTypeEnum.NATURAL_PERSON,
            lastName: lastName.value,
            firstName: firstName.value,
            phone: phone.value,
          });
          resetForm();
          context.emit("validate", tenant.id);
        } catch (error) {
          console.error(Error);
        }
      }
      validateInProgress.value = false;
    }

    const duplicateTenant = computed(() => {
      return tenantsStore.tenants.find(
        (tenant) =>
          tenant.firstName?.toLowerCase() === firstName.value.toLowerCase() &&
          tenant.lastName?.toLowerCase() === lastName.value.toLowerCase()
      );
    });

    const duplicateTenantRealEstateAsset = computed(() => {
      if (duplicateTenant.value)
        return realEstateAssetsStore.getRealEstateAsset(
          duplicateTenant.value?.realEstateAssetId
        );
    });

    function closeDialog() {
      resetForm();
      context.emit("close");
    }

    return {
      firstName,
      lastName,
      phone,
      validateInProgress,
      validate,
      resetForm,
      closeDialog,
      duplicateTenant,
      duplicateTenantRealEstateAsset,
    };
  },
});
